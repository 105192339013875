Draw.loadPlugin(function(ui) {
    const EDGE_STYLE = "edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;";
        

    var positionShape = null;
    var personShape = null;
    let libraryLoaded = ui.libraryLoaded;
    ui.libraryLoaded = function(library, shapes) {
        let res = libraryLoaded.apply(this, arguments);

        if (!(library instanceof UrlLibrary) || library.title !== "Omega") {
            return res;
        }

        positionShape ??= shapes.find(shape => shape.title === "position");
        personShape ??= shapes.find(shape => shape.title === "person");

        return res;
    }

    function createComponent(image, attributes, x=0, y=0) {
        let geometry = new mxGeometry(x, y, image.w, image.h);
        let style = "shape=image;verticalLabelPosition=bottom;verticalAlign=top;imageAspect=0;image=" + image.data.replace(";base64", "");
        let cell = new mxCell('', geometry, style);
        cell.vertex = true;
        for (key in attributes) {
            cell.updateCellProperty("omega-" + key, attributes[key]);
        };
        
        ui.editor.graph.addCell(cell);
        return cell;
    }

    function createEdge(source, target) {
        let geometry = new mxGeometry(0, 0, 0, 0);
        geometry.relative = true;
        let cell = new mxCell(null, geometry, EDGE_STYLE);
        cell.source = source;
        cell.target = target; 
        cell.edge = true;
        ui.editor.graph.addCell(cell);
        return cell;
    }

    function addSupport(supportShape, cell1, cell2, edge, attributes) {

        let x = (cell1.geometry.x + cell2.geometry.x) / 2;
        let y = (cell1.geometry.y + cell1.geometry.height - supportShape.h + cell2.geometry.y) / 2;
        let support = createComponent(supportShape, attributes, x, y);
        edge.target = support;
        edge.style = EDGE_STYLE;
        ui.editor.graph.removeCells([edge], false);
        createEdge(cell1, support);
        createEdge(support, cell2);
    }

    function addTree() {
        let bossId = 176;
        

        if (!positionShape || !personShape) {
            setTimeout(addTree, 1000);
            return;
        }

        let cell1 = createComponent(positionShape, {Position: 44715});
        let cell2 = createComponent(positionShape, {Position: 44715});
        let cell3 = createComponent(positionShape, {Position: 44715});
        
        let edge1 = createEdge(cell1, cell2);
        let edge2 = createEdge(cell1, cell3);

        let layout = new mxCompactTreeLayout(ui.editor.graph);
        layout.horizontal = false;
        layout.levelDistance = 120;
        layout.execute(ui.editor.graph.getDefaultParent());

        addSupport(personShape, cell1, cell2, edge1, {Person: 44715});
    }  

    ui.editor.addListener("resetGraphView", addTree);

});